@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  letter-spacing: 0.03em;
  background-color: lightgray; }

#root {
  height: 100vh;
  display: grid;
  place-items: center;
  padding: 20px; }

#app {
  background-color: #282a36;
  color: #bd93f9;
  display: grid;
  grid-template-rows: 1fr 1fr 5fr 1fr;
  width: 380px;
  height: 600px;
  border-radius: 20px;
  box-shadow: 5px 15px 40px rgba(0, 0, 0, 0.5);
  overflow: hidden; }

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: #f8f8f2;
  font-size: 24px; }

button:focus {
  border: none;
  outline: none; }

.setup {
  background-color: #44475a;
  display: grid;
  padding: 20px;
  font-size: 14px;
  grid-template-columns: 1fr 20px 40px 20px;
  border-bottom: 1px solid #282a36;
  -webkit-align-items: center;
          align-items: center; }

.length {
  text-align: center; }

#timer {
  border-bottom: 1px solid #44475a; }

.svg-timer {
  width: 380px;
  height: 380px;
  -webkit-transform: rotateZ(-90deg);
          transform: rotateZ(-90deg); }

#timer-circle {
  fill: #282a36;
  stroke-width: 10;
  stroke-linecap: round;
  transition: stroke-dashoffset 1s linear; }

#grad-circle {
  fill: none;
  stroke: #44475a;
  stroke-width: 2; }

#timer-text {
  z-index: 1000;
  position: relative;
  height: 0px;
  top: 140px;
  left: 0px;
  text-align: center;
  border-radius: 50%; }
  #timer-text #time-left {
    margin-top: 8px;
    font-size: 48px;
    color: #f1fa8c; }
  #timer-text #timer-label {
    color: #6272a4;
    font-size: 18px;
    font-weight: 400; }

#controls {
  background-color: #44475a;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 5fr 1fr; }

footer {
  font-size: 14px;
  width: 380px;
  box-sizing: border-box;
  padding: 40px;
  text-align: center;
  color: #282a36; }
  footer a {
    color: #bd93f9;
    text-decoration: none; }

